.search-area {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hotel-search-field {
    position: relative;
    margin-top: -8rem;
    display: flex;
    width: 40rem;
    align-items: center;
    background-color: #EAEAEA;
    padding: .5rem 1rem;
    justify-content: space-between;
    border-radius: 20px;
    transition: all .2s;
}

.hotel-search-field input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 90%;
    font-size: 1.2rem;
    font-family: 'Inter', sans-serif;
}

.hotel-search-field:hover {
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    box-shadow: 0px 4px 34px 4px rgba(0, 0, 0, 0.25);
}

.searchimage{
    height: 2rem;
}

.hotel-bg {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.hotel-image-for-search {
    height: 15rem;
    width: 71rem;
    border-radius: 15px;
    padding: 5rem;
    font-size: 2rem;
    color: white;
    box-shadow: 0px 4px 34px 4px rgba(0, 0, 0, 0.25);
    background: url('https://w0.peakpx.com/wallpaper/773/290/HD-wallpaper-luxury-hotel-honeymoon-swimming-pool-hotel-vacation-travelling.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.hotel-image-for-search h1{
    padding-right: 0.1rem;
    text-align: right;
}

@media (max-width:702px) {
    .hotel-image-for-search {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .hotel-search-field{
        width: 30rem;
    }
}