.waterimage {
    height: 2rem;
    width: auto;
}

.water {

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10rem 0;
}

.waterimage {
    height: 20rem;
    margin: 0 5rem;
    transition: all .5s;
    cursor: pointer;
}

.water img:hover {
    transform: scale(1.05);
}
.categories {
    padding: 0 5rem;
}

.activity-category {
    height: 12rem;
    width: 15rem;
    padding: 1rem;
    display: flex;
    margin-top: 2rem;
    margin-right: 1rem;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 10px;
    font-size: 1.5rem;
    border: 1px solid grey;
    transition: all .6s;
    cursor: pointer;
}
.activity-category:hover{
    box-shadow: 0 10px 35px 4px rgba(0, 0, 0, 0.18);
    border: none;
}

.icon-activity {
    height: 2rem;
    width: 2rem;
}

.activity-options {
    display: flex;
    flex-wrap: wrap;
}
@media (max-width:702px) {
    .water1 {
        margin-left: 4rem !important;
        margin-top: -30rem !important;
    }
}
@media (max-width:702px) {
    .water2 {
        margin-top: 20rem !important;
        margin-left: -35rem !important;
    }
}

.book-bg-image-act {
    height: 30rem;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.activity-container {
    position: relative;
    margin-top: -20rem;
}