.trip {
    padding: 2rem;
    color: #ffffff;
    margin-bottom: 5rem;
    /* height: 120vh; */
}

.box {
    position: relative;
    margin-top: -40rem;
}

.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3rem;
    width: 70rem;
    height: 40rem;
}

.box-right {
    height: 20rem;
    width: 40rem;
    background: rgba(108, 76, 37, 0.5);
    backdrop-filter: blur(2px);
    float: right;
    font-weight: 400;
    text-align: right;
    padding: 5rem;
    padding-bottom: 2rem !important;
    padding-left: 2rem !important;
    margin-bottom: 3rem;
    position: absolute;
    z-index: 2;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.price1 {
    text-align: left;
}

.price2 {
    text-align: right;
}

.box-left {
    height: 20rem;
    width: 40rem;
    background: rgba(32, 94, 99, 0.7);
    backdrop-filter: blur(2px);
    float: left;
    font-weight: 400;
    padding: 5rem;
    padding-bottom: 2rem !important;
    padding-right: 2rem !important;
    margin-bottom: 3rem;
    position: absolute;
    z-index: 2;
    top: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-width: 1200px) {
    .box-left {
        left: 5rem;
    }
    .box-right {
        right: 5rem;
    }
}

@media only screen and (min-width: 1500px) {
    .box-left {
        left: 10rem;
    }
    .box-right {
        right: 10rem;
    }
}

@media only screen and (min-width: 1600px) {
    .box-left {
        left: 15rem;
    }
    .box-right {
        right: 15rem;
    }
}

@media only screen and (min-width: 1800px) {
    .box-left {
        left: 20rem;
    }
    .box-right {
        right: 20rem;
    }
}

.trip-packages {
    margin-top: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.trip-list {
    display: flex;
    /* flex-wrap: wrap; */
}