:root {
    --surface-color: #fff;
    --curve: 40;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
    color: black;
}

.card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
}

.card__image {
    width: 100%;
    height: auto;
}

.card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(--curve) * 1px);
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: .2s ease-in-out;
}

.card:hover .card__overlay {
    transform: translateY(0);
}

.card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8em;
    padding: 1rem;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
    cursor: pointer;
}

.card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
    transform: translateY(0);
}

.card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.card__title {
    font-size: 1.5rem;
    margin: 0 0 .3em;
    color: black;
}

.card__tagline {
    display: block;
    margin: 1em 0;
    font-size: .8em;
    color: black;
}

.card__status {
    color: black;
    font-size: .8em;
}

.card__description {
    padding: 0 2em 2em;
    margin: 0;
    font-weight: 600;
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    color: black;

}

.card__right {
    font-size: 1rem;
    color: black;
    font-weight: 900;
}

.card__header-text {
    line-height: 20px;
}
.card__header-text span {
    font-weight: 700;
    font-size: 1.2rem;
    color: grey;
}

.filter-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
}