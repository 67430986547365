.profile-header{
    background: linear-gradient(87.83deg, rgba(0, 255, 25, 0.2) 0%, rgba(155, 62, 199, 0.108) 100%), #D3D5FF;
    height: 11rem;
    margin: 2rem;
    border-radius: 1rem;
}
.profile-image-icon{
    border-radius: 400px;
    height: 13rem;
    width: 13rem;
    margin-left: 4rem;
    margin-top: -7rem;
}

.profile-card-content {
    display: flex;
    padding: 0 2rem;
}
.pro-content {
    flex: .2;
    text-align: center;
}

.pro-other-details {
    flex: .8;
    padding: 1rem 5rem;
    font-size: 2.5rem;
}

.pro-input {
    height: 2.5rem;
    width: 20rem;
    margin: 1rem;
    padding: 0 .5rem;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 10px;
}

.name-details {
    display: flex;
}