    .heading {
        font-size: 5em;
        font-weight: 800;
        padding: 3rem;
        display: flex;
    }
    
    .photo {
        display: flex;
    }
    
    .watersportsimage {
        border-radius: 4rem;
        height: 38rem;
        width: 18rem;
        padding: 2rem;
    }
    
    .photo img:nth-child(1),
    .photo img:nth-child(3) {
        margin-top: 5rem;
    }