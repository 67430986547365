.footer-dark {
    color: #f0f9ff;
    background-color: #282d32;
}

.row {
    display: flex;
    margin: 1rem;
}

.item {
    padding: 2rem;
}

.footer-dark h2 {
    margin-bottom: 20px;
    font-weight: bold;
}

.footer-dark ul {
    list-style: none;
    line-height: 1.5;
    font-size: 1rem;
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
}

.footer-dark ul a:hover {
    opacity: 0.8;
}

@media (max-width:767px) {
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 2px;
        padding-left: 2rem;
    }
}

@media (min-width:767px) {
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 2px;
        padding-left: 5rem;
    }
}

@media (max-width:767px) {
    .footer-dark .item.text {
        margin-bottom: 0;
    }
}

.footer-dark .item.text p {
    opacity: 0.6;
    margin-bottom: 0;
    margin-top: 1.7rem;
    line-height: 1.5rem;
}


@media (max-width:991px) {
    .footer-dark .item.social {
        text-align: center;
        margin-top: 20px;
    }
}

.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: 0.3;
    font-size: 1rem;
    color: white;
    padding-bottom: 1rem;
}

.social ul {
    display: flex;
    justify-content: space-between;
}

.social ul li {
    list-style: none;
}

.social ul li a {
    display: block;
    text-align: center;
    margin: 0 10px;
    border-radius: 50%;
    padding: 6px;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, #ddd, #fff);
    transition: .5s;
}

ul li a .fab {
    display: block;
    background: linear-gradient(0deg, #fff, #ddd);
    border-radius: 50%;
    line-height: calc(60px - 12px);
    font-size: 24px;
    color: #262626;
    transition: .5s;
}

.social ul li a:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    text-decoration: none;
}

ul li:nth-child(1) a:hover .fab {
    color: #3b5998;
}

ul li:nth-child(2) a:hover .fab {
    color: #980e21;
}

ul li:nth-child(3) a:hover .fab {
    color: #357adb;
}

ul li:nth-child(4) a:hover .fab {
    color: #e4405f;
}

ul li:nth-child(5) a:hover .fab {
    color: #007bb6;
}