.trip-card {
    color: black;
    margin: 1rem 2rem;
    padding: 1rem;
    transition: all .4s;
    border-radius: 10px;
    cursor: pointer;
}

.trip-card:hover {
    box-shadow: 0 10px 35px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgb(120, 120, 120);

}

.trip-thumb {
    height: 15rem;
    width: 15rem;
    border-radius: 5px;
}