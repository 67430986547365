.bg-image {
  width: 100%;
  height: auto;
}

.home-page-content {
  padding: 0 8rem;
  font-size: 2rem;
  text-align: right;
  position: relative;
  margin-top: -8rem;
}

.home-page-content h1 {
  font-size: 6rem;
}

.bg-sky {
  height: 40rem;
}

.middle-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.plane {
  height: 20rem;
  position: relative;
  margin-top: -10rem;
  margin-left: -30rem;
}
.middle-section {
  margin-top: 20rem;
}
.middle-section h1 {
  font-size: 5rem;
  margin-right: 2rem;
}

.road-trip {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}

.road-trip-side-bg {
  height: 55rem;
  width: 50rem;
  background: url("https://images.unsplash.com/photo-1536420124392-79a70082fbe5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cm9hZCUyMGluJTIwZm9yZXN0fGVufDB8fDB8fA%3D%3D&w=1000&q=80");
  display: flex;
  justify-content: center;
}

.road-trip-side-bg h1 {
  margin-top: 10rem;
  color: white;
  font-size: 5rem;
}

.road-trip-top {
  display: flex;
}

.road-trip-overlay {
  position: relative;
  background: rgba(108, 109, 46, 0.5);
  height: 40rem;
  margin-top: 10rem;
  text-align: center;
  display: flex;
  align-items: center;
  margin-right: -20rem;
  padding: 5rem;
  width: 40rem;
  font-size: 2rem;
  color: white;
}

.road-trip-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  font-size: 2rem;
  text-align: center;
}
.book-now {
  position: absolute;
  margin-top: 45rem;
  margin-left: 30rem;
  width: 15rem;
  padding: 1rem 0;
  font-size: 2rem;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 10px;
  background: rgba(28, 33, 75, 0.249);
  box-shadow: 0px 4px 11px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  color: white;
  font-family: 'Amatic SC', cursive;
  transition: all .5s;
  cursor: pointer;
}

.book-now:hover{
    transform: scale(1.04);
    background: rgba(28, 33, 75, 0.469);
}

@media only screen and (min-width: 1550px) {
  .road-trip-overlay {
    width: 30rem;
    height: 30rem;
    margin-top: 15rem;
    font-size: 1.8rem;
  }
}


@media only screen and (min-width: 1550px) {
  .road-trip-overlay {
    width: 30rem;
    height: 30rem;
    margin-top: 15rem;
    font-size: 1.8rem;
  }
}


@media only screen and (min-width: 750px) {
  .road-trip-overlay {
    width: 30rem;
    height: 30rem;
    margin-top: 15rem;
    font-size: 1.8rem;
    margin-left: 10rem;
  }
}